import { render, staticRenderFns } from "./DoctorsListItemtest.vue?vue&type=template&id=6558bdb3&scoped=true&"
import script from "./DoctorsListItemtest.vue?vue&type=script&lang=js&"
export * from "./DoctorsListItemtest.vue?vue&type=script&lang=js&"
import style0 from "./DoctorsListItemtest.vue?vue&type=style&index=0&id=6558bdb3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6558bdb3",
  null
  
)

export default component.exports