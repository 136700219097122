<template>
  <div>
    <div class="tbox zzjz">
      <!-- <div class="doc"> -->
        <!-- <doctors-list :doctors="doctors" class="doc"></doctors-list> -->
        <el-row>
          <!-- <el-col :span="25" v-for="project in doctors" :key="project.pid">
              <doctors-list-itemtest :doctors-item="project" class="now"></doctors-list-itemtest>
          </el-col> -->
          <el-col :span="25">
              <doctors-list-itemtest :doctors-item="detail" class="now"></doctors-list-itemtest>
          </el-col>
      </el-row>
      <!-- </div> -->
      <div class="lbox">
        <!-- <div style="position:relative"> -->
        <el-row class="lrow">
          <el-col>
            <span class="cvideo">
              <img src="~assets/connectaudio.svg" alt=""></span></el-col>
          <el-col v-for="(item,index) in relatevideos" :key="index" class="videosize">
            <small-videos-list-itemtest :videos-item="item" class="sv1"></small-videos-list-itemtest>
          </el-col>
        </el-row>
        <el-row class="lrow">
          <el-col>
            <span class="cdoc">
              <img src="~assets/docw.svg" alt="">
            </span>
          </el-col>
          <el-col v-for="(item,index) in videos" :key="index" class="videosize">
            <div v-if="index < 4">
            <small-videos-list-itemtest :videosItem="item" class="sv2"></small-videos-list-itemtest>
            </div>
          </el-col>
        </el-row>
      </div>
      <span class="vt1">{{detail.infoTitle}}</span>
        <span class="vttime">{{startTime}}</span>
        <span class="vname">音频内容</span>
        <span class="vcon">
          <el-row>
            <el-col :span="24" v-for="(item, index) in msg" :key="index">
              {{item}}
            </el-col>
          </el-row>
                    <el-row style="margin-top:30px">
              <el-col>
      <div class="_xw2rajjf48q"></div>
              </el-col>
            </el-row>
          </span>
<div class="playvideo">
  <div class="player">
    <!-- <div class="img-gif"></div>
    <span class="name">{{currentDuration}}|{{duration}}</span>
    <span class="time">{{currentDuration}}|{{duration}}</span> -->
    <!-- 进度条 -->
    <!-- <div class="progress-wrapper"> -->
      <!-- 时间显示 -->
      <!-- <div class="progress-bar-wrapper">
        <div class="progress-bar" ref="progressBar" @click="progressClick">
          <div class="bar-inner">
            <div class="progress" ref="progress"></div>
            <div class="progress-btn-wrapper" ref="progressBtn">
              <div class="progress-btn"></div>
            </div>
          </div>
        </div>
      </div> -->
    <!-- </div> -->
    <!-- 操作按钮 -->
    <el-row type="flex" style="justify-content: center; align-items: center; margin-top:11px">
      <el-col :span="3">
        <div class="operators">
      <!-- <img :src="require('@/assets/voice/back.png')" @click="fastBack" class="i-left"/> -->
        <img :src="playImage" @click="changeStatus(false)"/>
      <!-- <img :src="require('@/assets/voice/prev.pngnpm install --save-dev node-sass')" @click="forward" class="i-left"/> -->
    </div>
      </el-col>
      <el-col :span="21">
        <el-row>
          <el-col class="dettitle">
            {{detail.infoTitle}}
          </el-col>
          <el-col class="dettime">
            {{duration}}
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row>
      <el-card class="card" :body-style="{ padding: '10px' }">
        <div id="waveform" ref="waveform"></div>
      </el-card>
    </el-row>
    <!-- <audio
      controls
      id="maudio"
      ref="maudio"
      preload="auto"
      autoplay
      style="display:none;"
      @timeupdate="onTimeupdate"
      @ended="audioEnd"
    >
      <source :src="audioURL" ref="msource" type="audio/wav" />您的浏览器不支持音频播放
    </audio> -->
  </div>
</div>
    </div>
    <div class="mianbox zzjz">
      <div class="mian">
        <recommend-title :Title="'相关推荐'" :eTitle="'VOICE ANSWER'" :color="'#4263EB'" class="rectitle"></recommend-title>
        <el-row style="margin-top:80px; width:950px" v-if="relaterecommended.length != 0 ">
          <el-col :span="24" v-for="project in relaterecommended" :key="project.pid" :offset="1" class="elhover">
            <long-videos-listtest :totop="true" :videos="project" class="now ql"></long-videos-listtest>
          </el-col>
        </el-row>
        <el-empty v-else description="描述文字" style="margin-top:100px; width: 100px;
    text-align: center;"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
// import LongVideosList from 'components/content/videos/LongVideosList'
import LongVideosListtest from 'components/content/videos/LongVideosListtest'
import SmallVideosListItemtest from 'components/content/videos/SmallVideosListItemtest'
// import DoctorsList from 'components/content/doctors/DoctorsList'
import DoctorsListItemtest from 'components/content/doctors/DoctorsListItemtest'
import RecommendTitle from 'components/content/recommendtitle/RecommendTitle'
// import { Decrypt } from '@/util/EncryptionToDecrypt'
import WaveSurfer from 'wavesurfer.js'
// import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js'

import { getMediadetailList } from 'network/getData'
import formateDate from 'util/formateDate'
import subString from 'util/changeString'
import stohs from 'util/s_to_hs'
export default {
  name: 'VideoDetail',
  components: {
    // DoctorsList,
    DoctorsListItemtest,
    LongVideosListtest,
    SmallVideosListItemtest,
    RecommendTitle
    // LongVideosList
    // WaveSurfer
  },
  data () {
    return {
      currentTime: 0,
      currentDuration: '00:00:00',
      percent: 0,
      playing: true,
      showNum: 1200,
      relaterecommended: [],
      wavesurfer: '',
      detail: [],
      msg: [],
      videos: [],
      relatevideos: [],
      startTime: '',
      url: '',
      duration: '',
      title: '音频',
      titlecontent: '1',
      playImage: require('../../assets/voice/play.png')
    }
  },
  // metaInfo () {
  //   return {
  //     title: this.title + '_瑞尔安心',
  //     meta: [
  //       { name: 'keywords', content: this.title },
  //       { name: 'description', content: this.titlecontent }
  //     ]
  //   }
  // },
  async created () {
    this.touch = {}
  },
  async mounted () {
    // const id = sessionStorage.getItem('id')
    // await this.getMediadetailListdata(id, 'audio')
    await this.getMediadetailListdata(this.$route.query.audioId, 'audio')
    this.createWvesurfer();
    (window.slotbydup = window.slotbydup || []).push({
      id: 'u6745378',
      container: '_xw2rajjf48q',
      async: true
    })
  },
  methods: {
    async createWvesurfer () {
      const that = this
      this.wavesurfer = WaveSurfer.create({
        container: this.$refs.waveform,
        waveColor: '#8DA6F9',
        barWidth: 1,
        barHeight: 10,
        cursorColor: 'black',
        progressColor: 'blue',
        backend: 'MediaElement',
        barGap: 10,
        minLength: 5,
        pixelRatio: 1,
        audioRate: '1'
      })
      await this.wavesurfer.load(this.url)
      await this.wavesurfer.on('ready', percents => {
        this.duration = stohs(parseInt(this.wavesurfer.getDuration()))
        console.log(this.duration)
      })
      await this.wavesurfer.on('audioprocess', function (e) {
        if (that.changeTime(that.wavesurfer.getCurrentTime()) >= that.duration) {
          // console.log(that.wavesurfer.getCurrentTime())
        }
      })
      this.wavesurfer.on('finish', function () {
        that.changeStatus(true)
      })
    },
    changeTime (seconds) {
      seconds = Number(seconds)
      const minutes = Math.floor(seconds / 60)
      seconds = seconds % 60
      let secondsStr = Math.round(seconds).toString()
      secondsStr = seconds.toFixed(2)
      if (minutes > 0) {
        return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + secondsStr : secondsStr}`
      }
      return `00:${seconds < 10 ? '0' + secondsStr : secondsStr}`
    },
    async getMediadetailListdata (infoId, type) {
      try {
        const result = await getMediadetailList(infoId, type)
        this.videos = result.data.data
        this.detail = result.data.data.detail
        this.msg = subString(this.detail.content)
        this.videos = result.data.data.doctorWorks
        this.relatevideos = result.data.data.relatedContent
        this.relaterecommended = result.data.data.relatedScienceContent
        // const d = new Date(this.detail.startTime)
        const d = this.detail.startTime ? new Date(this.detail.startTime) : ''
        this.url = this.detail.infoUrl
        this.startTime = formateDate(d)
        this.title = this.detail.infoTitle
        this.titlecontent = this.msg[0].length > 100 ? this.msg[0].slice(0, 100) : this.msg[0]
      } catch (error) {
        // console.log(error)
      }
    },
    // 改变播放状态
    changeStatus (e) {
      if (e) {
        this.playing = !this.playing
      } else {
        this.wavesurfer.playPause.bind(this.wavesurfer)()
        this.playing = !this.playing
      }
    }
  },
  watch: {
    playing: function (newVal) {
      if (newVal) {
        this.playImage = require('../../assets/voice/play.png')
      } else {
        this.playImage = require('../../assets/voice/End.png')
      }
    },
    $route: function (newVal) {
      // const id = sessionStorage.getItem('id')
      this.getMediadetailListdata(this.$route.query.audioId, 'audio')
    }
  }
}
</script>

<style scoped>
.tbox{
  position: absolute;
  width: 1200px;
  height: 751px;
  /* left: 120px; */
  /* margin-left: 120px; */
  top: 100px;
  /* background: #FAFAFA; */
}
.doc{
  position: absolute;
  width: 232px;
  height: 415px;
  left: 20px;
  top: 20px;
}
.lbox{
  position: absolute;
  width: 302px;
  height: 645px;
  left: 898px;
  top: 26px;
  border: 1px solid #EAEDF0;
  box-sizing: border-box;
}
.cvideo, .cdoc{
  /* position: absolute; */
  /* width: 96px;
  height: 28px;
  left: 23px;
  top: 15px; */
  font-family: "PangMenZhengDao-3";
  font-size: 24px;
  line-height: 28px;
  color: #262626;
  /* margin-left: 30px; */
}
.sv1{
  /* position: absolute; */
  width: 269px;
  height: 52px;
  left: 12px;
  /* top: 61px; */
}
.sv2{
  /* position: absolute; */
  width: 269px;
  height: 52px;
  left: 12px;
  /* top: 315px; */
}
.dettitle{
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}
.dettime{
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
}
.vt1{
  position: absolute;
  /* width: 336px; */
  height: 38px;
  left: 272px;
  top: 20px;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #000000;
}
.vttime{
  position: absolute;
  width: 174px;
  height: 24px;
  left: 272px;
  top: 68px;
  color: #8C8C8C;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.vname{
  position: absolute;
  width: 580px;
  height: 28px;
  left: 272px;
  top: 273px;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}

.vcon{
position: absolute;
width: 580px;
/* height: 168px; */
left: 272px;
top: 323px;
font-size: 16px;
line-height: 24px;
color: #262626;
}
.vcon .el-col{
  margin-top: 10px;
}
.playvideo{
position: absolute;
width: 601px;
height: 138px;
left: 272px;
top: 100px;
border: 1px solid #6F8CF3;
border-radius: 14px;
background-color: #6F8CF3;
}
.mianbox{
  position: absolute;
width: 1200px;
/* height: 1610px; */
/* left: 120px; */
top: 901px;
}
.mian{
  display: flex;
flex-direction: column;
align-items: center;
padding: 0px;
/* position: absolute; */
width: 1200px;
/* height: 1534px; */
margin-bottom: 200px;
/* left: 125px; */
top: 0px;
}
.videosize{
  margin-top: 10px;
}
.lrow{
    margin-top: 21px;
  /* margin-bottom: 24px; */
}
.lv{
  position: absolute;
  top:100px;
  /* margin-left: 120px; */
}
.player {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.img-gif {
  position: absolute;
  top: 70px;
  width: 420px;
  height: 50px;
  left: 50px;
  /* border: 3px solid rgba(238, 230, 255, 1); */
  /* border-radius: 40px 40px 4px 40px; */
  /* background: url("../../../../assets/common/audio-gif.gif") no-repeat center; */
  margin: 0px auto;
}
.progress-wrapper {
  display: flex;
  align-items: center;
  width: 75%;
  margin: 0px auto;
  padding: 10px 0;
  position: absolute;
  top: 105px;
  left: 50px;
}
.progress-bar-wrapper {
  flex: 1;
  margin-right: 5px;
}
.progress-bar {
    height: 30px;
    cursor: pointer;
}
.progress-btn-wrapper {
  position: absolute;
  left: -8px;
  top: -13px;
  width: 30px;
  height: 30px;
}
.bar-inner {
  position: relative;
  top: 13px;
  height: 4px;
  background: rgba(238,229,255,1);
}
.progress {
  position: absolute;
  height: 100%;
  background-color: #bc99ff;
}
.progress-btn {
    position: relative;
    top: 7px;
    left: 7px;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border: 3px solid #fff;
    border-radius: 50%;
    background: #bc99ff;
}
.time {
  color: rgba(238, 229, 255, 1);
  font-size: 12px;
  width: 110px;
  text-align: left;
  position: absolute;
  left: 80px;
  top: 42px;
}
.operators {
  cursor: pointer;
  /* text-align: center; */
  width: 35px;
  height: 35px;
  /* margin-top: 17px; */
}
.operators img{
  width: 100%;
  height: 100%;
}
img{
  vertical-align: middle;
  margin: 0 20px;
}
.name{
  position: absolute;
  height: 24px;
  left: 80px;
  right: 261px;
  top: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}
::v-deep .mixin-components-container {
  width: 100% !important;
  background-color: #f0f2f5;
  padding: 30px;
  /* min-height: calc(100vh - 84px); */
}
::v-deep .el-card{
  border: unset;
  background-color: unset;
  box-shadow: unset;
}
::v-deep .el-card__body {
  width: 100% !important;
  height: 70px !important;
  padding: 0 auto !important;
}
::v-deep .card {
  width: 100% !important;
  height: 70px;
}
::v-deep #waveform {
  margin-top: 10px;
  margin-left: -10px;
  margin-right: 10px;
  height: 40px !important;
}
::v-deep wave {
  height: 40px !important;
  overflow: hidden !important;
}
</style>
