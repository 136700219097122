<template>
  <div class="doctors-item" @click="itemClick(doctorsItem.doctorId)">
    <div class="imgbox">
      <!-- <div class="img"></div> -->
      <el-avatar :size="148" :src="doctorsItem.avatar"></el-avatar>
    </div>
    <div class="doctitle">
      <span class="docname">{{ doctorsItem.doctorName }}</span>
      <span class="docposition">
        <span class="a">{{
          doctorsItem.doctorTitle.length > 8
            ? doctorsItem.doctorTitle.slice(0, 7) + "..."
            : doctorsItem.doctorTitle
        }}</span></span
      >
    </div>
    <div class="doctors-info">
      <div class="hospital">{{ doctorsItem.hospital }}</div>
      <span class="department">{{ doctorsItem.department }}</span>
    </div>
    <div class="goodto">
      <span class="good">{{ doctorsItem.goodAt }}</span>
    </div>
    <!-- <doc-nav-bar :nums-item = "nums" class="doc"></doc-nav-bar> -->
    <div class="bottommsg">
      <el-row type="flex">
        <el-col :span="8">
          <el-row>
            <el-col style="color: #4263EB">{{ doctorsItem.videoNum }}</el-col>
            <el-col class="btitle">视频</el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-col style="color: #F6A609">{{ doctorsItem.audioNum }}</el-col>
          <el-col class="btitle">音频</el-col>
        </el-col>
        <el-col :span="8">
          <el-col style="color: #595959">{{ doctorsItem.articleNum }}</el-col>
          <el-col class="btitle">文章</el-col>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// import DocNavBar from './DocNavBar.vue'
export default {
  name: "DoctorsListItem",
  components: {
    // DocNavBar
  },
  data() {
    return {
      nums: {},
    };
  },
  props: {
    doctorsItem: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    doctorsIndex: {
      type: Number,
    },
    TypeItem: {
      type: String,
    },
  },
  mounted() {
    for (const o in this.doctorsItem) {
      // // console.log(o)
      // // console.log(this.doctorsItem[o])
      // this.nums[o]=o.valueOf
      if (o === "articleNum" || o === "audioNum" || o === "videoNum") {
        this.nums[o] = this.doctorsItem[o];
      }
    }
    // // console.log(this.nums)
  },
  methods: {
    itemClick(e) {
      // this.$router.push('/doctordetail/' + e)
      // // sessionStorage.setItem('nav', '6')
      // sessionStorage.setItem('id', e)
      this.$router.push({ path: "/mobVideoCenterHome", query: { docId: e } });
    },
  },
};
</script>

<style scoped>
.doctors-item {
  width: 232px;
  height: 415px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
}
.imgbox {
  width: 156px;
  height: 156px;
  margin: 0 auto;
  margin-top: 24px;
}
::v-deep .el-avatar > img {
  height: unset;
  width: 100%;
}
.doctitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.docname {
  /* font-family: 'OPPOSans B'; */
  font-weight: bold;
  flex: 1;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #262626;
  margin-right: 12px;
}
.docposition {
  flex: 1;
  text-align: left;
  font-size: 11px;
  line-height: 16px;
  box-sizing: border-box;
  color: #4263eb;
}
.docposition .a {
  padding: 1px 2px;
  border: 1px solid #4263eb;
  border-radius: 2px;
}
.doctors-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  /* position: absolute; */
  width: 156px;
  height: 42px;
  margin: 0 auto;
  margin-top: 18px;
  margin-bottom: 28px;
}
.doctors-info .hospital {
  position: static;
  width: 176px;
  /* height: 20px; */
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #595959;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 2px 0px;
}
.doctors-info .department {
  position: static;
  /* width: 48px; */
  height: 20px;
  left: 54px;
  top: 22px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #595959;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 2px 0px;
}
.goodto {
  width: 200px;
  height: 40px;
  margin: 0 auto;
}
.good {
  width: 200px;
  height: 40px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #8c8c8c;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.doc {
  margin-top: 30px;
  /* position: absolute; */
}
.bottommsg {
  position: absolute;
  bottom: 10px;
  /* display: flex; */
  width: inherit;
  text-align: center;
}
.btitle {
  font-size: 14px;
  line-height: 22px;
}
</style>
